.navbar__close__logo {
  display: flex;
  align-items: center;
  padding-top: 7px;
  width: 3rem;

  img {
    width: 100%;
    image-rendering: pixelated;
    -webkit-user-drag: none;
  }
}

@media screen and (min-width: 768px) {
  .navbar__close__logo {
    width: 3.5rem
  }
}